import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { apiLogin } from "../../apis/Auth";
import { setLocalStorage } from "utils/CommomFunction";
import { useHistory } from "react-router-dom";
// import { firebaseMessaging } from "firebaseInit";
import {
  isContainSpace,
  isContainSpecialCharacters,
  isValidVietnamese,
} from "utils/Validation";
import PopupWarningFirebase from "components/Notification/PopupWarningFirebase";
import { useTranslation } from "react-i18next";
import Container from "layouts/Container";
import { ThemeContext } from "handlers/ThemeContext";

const LoginForm = ({ checkNotificationPermission = () => {} }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const refPopupWarningFirebase = React.useRef(null);
  const [remember, setRemember] = useState(false);
  const onFinish = async (values) => {
    // checkNotificationPermission();
    // const fcmToken = await localStorage.getItem("fcmToken");
    // if(!fcmToken){
    //   refPopupWarningFirebase?.current?.show?.();
    //   return;
    // };

    setIsLoading(true);
    const dataLogin = { ...values };
    apiLogin(dataLogin)
      .then((res) => {
        console.log("response api login : ", res);
        setIsLoading(false);
        const accountType =
          res?.data?.generateMerchantToken?.account_type.split(",");
        const allowedRoles = ["admin", "manager", "cashier", "host", "kitchen"];
        const isAllowed = allowedRoles?.some((role) =>
          accountType?.includes(role)
        );
        if (
          !res.errors &&
          res.data &&
          res.data.generateMerchantToken &&
          isAllowed
        ) {
          setError(false);
          // setLocalStorage(
          //   "accept_order",
          //   res.data.generateStaffToken.accept_order
          // );
          // setLocalStorage("address", res.data.generateStaffToken.address);
          // setLocalStorage("lastname", res.data.generateStaffToken.lastname);
          // setLocalStorage("restaurant", res.data.generateStaffToken.restaurant);
          // setLocalStorage("storeId", res.data.generateStaffToken.id);
          setLocalStorage("access_token", res.data.generateMerchantToken.token);
          // setLocalStorage("weekday", res.data.generateStaffToken.weekday);
          // setLocalStorage(
          //   "store_status",
          //   res.data.generateStaffToken.store_status
          // );
          // setLocalStorage("weekend", res.data.generateStaffToken.weekend);
          history.push("/home");
        } else {
          setError(true);
        }
      })
      .finally(() => {
        if (remember) {
          localStorage.setItem("us-923", btoa(values.username));
          localStorage.setItem("pw-155", btoa(values.password));
        } else {
          localStorage.removeItem("us-923");
          localStorage.removeItem("pw-155");
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateUsername = (value, field) =>
    isContainSpace(value, field).then((res) =>
      res ? isValidVietnamese(value, field) : ""
    );
  const validatePassword = (value, field) =>
    isContainSpace(value, field).then((res2) =>
      res2 ? isContainSpecialCharacters(value, field) : ""
    );
  const { t, i18n } = useTranslation();
  const { isLightMode } = useContext(ThemeContext);
  const [form] = Form.useForm();
  const encodedUsername = localStorage.getItem("us-923");
  const encodedPassword = localStorage.getItem("pw-155");
  useEffect(() => {
    if (encodedUsername && encodedPassword) {
      form.setFieldsValue({
        username: atob(encodedUsername),
        password: atob(encodedPassword),
      });
      setRemember(true);
    }
  }, [encodedUsername, encodedPassword]);
  return (
    <Container>
      <Form
        name="basic"
        className={`login-form ${isLightMode ? "light" : ""}`}
        style={{ margin: "none" }}
        initialValues={{ remember: true }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className={`login-top ${isLightMode ? "light" : ""}`}>
          <div className={`login-box ${isLightMode ? "box-light" : ""}`}>
            <div className="login-header">
              <div className="header-title">
                <h3>Welcome back</h3>
              </div>
            </div>
            <span
              className={`input-name-text ${isLightMode ? "lightText" : ""}`}
            >
              {t("Username")}
            </span>
            <Form.Item name="username" required={false}>
              <Input
                className={`login-input ${
                  isLightMode ? "login-input-light" : ""
                }`}
                placeholder={"Username"}
              />
            </Form.Item>
            <span
              className={`input-name-text ${isLightMode ? "lightText" : ""}`}
            >
              {t("Password")}
            </span>
            <Form.Item name="password">
              <Input.Password
                className={`login-input ${
                  isLightMode ? "login-input-light" : ""
                }`}
                placeholder={t("password")}
              />
            </Form.Item>
            {error && (
              <div style={{ color: "var(--error-2-default)" }} className="err">
                <span>{t("loginError")}</span>
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <span
                style={{
                  color: "var(--text-secondary)",
                  fontSize: 14,
                  fontWeight: "400",
                }}
              >
                Remember Me
              </span>
              <Checkbox
                style={{ marginBottom: 5 }}
                defaultChecked={encodedPassword ? true : false}
                onChange={(e) => setRemember(e.target.checked)}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 20,
                fontSize: 16,
                color: "#F8CC3E",
                fontWeight: 600,
              }}
            ></div>
            <Form.Item className="login-button-container">
              <Button
                loading={isLoading}
                className="login-button"
                htmlType="submit"
              >
                {t("loginText")}
              </Button>
            </Form.Item>
          </div>
        </div>
        <div
          style={{
            fontSize: 17,
            marginTop: 30,
            color: isLightMode ? "black" : "white",
          }}
        >
          <span>Having troubles? </span>
          <a href={"https://levinci.group/contact-us/"}>
            <span
              style={{
                color: "#F8CC3E",
                fontWeight: 600,
                cursor: "pointer",
              }}
            >
              Contact Levinci Support
            </span>
          </a>
        </div>
        <div style={{ marginTop: 30, color: isLightMode ? "black" : "white" }}>
          <span>By continuing, you confirm that you agreed with our</span>
        </div>
        <div>
          <a href="https://levinci.group/">
            <span
              style={{
                color: "#F8CC3E",
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={() => history.push("/contact")}
            >
              Terms and Conditions.
            </span>
          </a>
        </div>
        <div style={{ marginTop: 20 }}>
          <span
            style={{
              color: isLightMode ? "black" : "white",
              fontWeight: 400,
              cursor: "pointer",
            }}
          >
            Version 1.0.0.1
          </span>
        </div>
      </Form>

      <PopupWarningFirebase ref={refPopupWarningFirebase} />
    </Container>
  );
};

export default LoginForm;
